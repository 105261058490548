import $ from 'jquery';

$(document).ready(function() {
		
	$(window).scroll(function() {
		var sticky = $('.header');
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) {
			sticky.addClass('fixed');
		} else {
			sticky.removeClass('fixed');
		}
	});

	//HAMBURGER
	
	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $(".header").toggleClass("toggled");
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $(".header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});

	$('.dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');
	$('.dropdown-submenu > a').on("click", function(e) {
		var dropdown = $(this).parent().find(' > .show');
		$('.dropdown-submenu .dropdown-menu').not(dropdown).removeClass('show');
		$(this).next('.dropdown-menu').toggleClass('show');
		e.stopPropagation();
	});
	$('.dropdown').on("hidden.bs.dropdown", function() {
		$('.dropdown-menu.show').removeClass('show');
	});

	$(".top_bar__search i").click(function(){
		$(".top_bar__search__desktop").slideToggle(300);
	});

	$(".all_themes").click(function(){
		$(".all_themes__list").slideToggle(300);
	});



});